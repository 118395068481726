import React, { useState } from "react";
import ContactFormModal from "../ContactFormModal/ContactFormModal";
import ServicesButton from "../Developments/ServicesButton/ServicesButton";
const ModalCard = ({ service }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const { icon_url, title, description, id } = service;

  // serviceid for contact form
  const [serviceId, setServiceId] = useState();

  return (
    <div className="col">
      <div className=" h-100 service-card border-0">
        <img src={icon_url} className=" img-fluid service-img" alt="..." />
        <div className="service-body-text ">
          <h5 className="service-title">{title}</h5>
          <p className="service-description m-0">{description}</p>
        </div>
        <p
          onClick={() => {
            handleShow();
            setServiceId(id);
          }}
          style={{ cursor: "pointer", textAlign: "center" }}
        >
          <ServicesButton>{"Inquiry"}</ServicesButton>
        </p>
        {/* form modal  */}
        <ContactFormModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          serviceId={serviceId}
        ></ContactFormModal>
      </div>
    </div>
  );
};

export default ModalCard;
