import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "../../../images/logo.png";
import "./Navigation.css";

const Navigation = () => {
  return (

    <Navbar collapseOnSelect expand="lg" variant="dark" style={{ backgroundColor: "black" }} className="nav-header">
      <Container fluid>
        <Navbar.Brand href="https://techsistltd.com/" target="_blank"><img src={logo} alt="" className='img-fluid' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto text-center">
            <Nav.Link as={Link} to="/home">Home</Nav.Link>
            <Nav.Link href="https://techsistltd.com/solutions" target="_blank">Solutions</Nav.Link>
            <Nav.Link href="https://techsistltd.com/contact" target="_blank">Contacts</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


  );
};

export default Navigation;