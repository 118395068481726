import React from "react";
import "./ServicesButton.css";

const ServicesButton = ({ children }) => {
  return (

    <span className="all-services">{children}</span>

  );
};

export default ServicesButton;
